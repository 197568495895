// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import jquery from 'jquery';
window.$ = window.jquery = jquery;
window.jQuery = window.jquery = jquery;
import "chartkick/chart.js"

$(document).on("turbolinks:before-visit", function() {
    const gallery = $('.gallery a').simpleLightbox(
        {
            maxZoom: 3,
            scrollZoomFactor: 0.025,
            disableRightClick: true
        }
    );
    const gallery1 = $('.quickview a').simpleLightbox(
        {
            maxZoom: 3,
            scrollZoomFactor: 0.025,
            disableRightClick: true,
            loop: true,
            rel: true
        }
    );
});

import 'popper.js'
import 'bootstrap'

import intlTelInput from 'intl-tel-input'
global.intlTelInput = intlTelInput;

import Rails from '@rails/ujs';
global.Rails = Rails

import alertify from 'alertifyjs';
global.alertify = alertify;

import LocalTime from 'helpers/local-time'
LocalTime.start()

import flatpickr from "flatpickr";
require('flatpickr/dist/flatpickr.css')

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// require("@nathanvda/cocoon")

import 'select2'
require('select2/dist/css/select2.css')
require('@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css')

import "@fortawesome/fontawesome-pro/css/all"

require('./nested-fields/addFields')
require('./nested-fields/removeFields')
require('./appointments')
require('./markup')
require('simplelightbox/dist/simple-lightbox.min.css')
require('simplelightbox/dist/simple-lightbox.jquery.min')

import "controllers"

// import Swiffy Slider JS
import { swiffyslider } from 'swiffy-slider'
import { swiffysliderextensions } from 'swiffy-slider/dist/js/swiffy-slider-extensions.min'


// Document ready function
document.addEventListener("turbolinks:load", () => {
    initFlatpickr();
    initPhoneNumberInput();
    initTooltip();
    initSelect2();
    initModalSelect2();
    initGallery();
    initSwiffySlider();
    initFadeInElements();
});

function initFlatpickr() {
    flatpickr("[data-behavior='flatpickr']", {
        weekNumbers: true,
        enableTime: false,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d"
    })
}

// Initialize phone number input with intl-tel-input
function initPhoneNumberInput() {
    const telInput = document.querySelector('#user_phone');

    if (telInput) {
        const iti = window.intlTelInput(telInput, {
            preferredCountries: ['US', 'CA', 'MX', 'GB', 'FR', 'DE', 'AU', 'NZ'],
            nationalMode: true,
            separateDialCode: true,
            formatOnDisplay: true,
        });

        telInput.addEventListener('keyup', formatIntlTelInput);
        telInput.addEventListener('change', formatIntlTelInput);

        function formatIntlTelInput() {
            if (typeof intlTelInputUtils !== 'undefined') {
                const currentText = iti.getNumber(intlTelInputUtils.numberFormat.E164);
                if (typeof currentText === 'string') {
                    iti.setNumber(currentText);
                }
            }
        }
    }
}

// Initialize Bootstrap tooltips
function initTooltip() {
    $('[data-toggle="tooltip"]').tooltip();
}

// Initialize Select2 elements
function initSelect2() {
    $('[data-s2="true"]').select2({
        theme: 'classic',
        width: 'resolve',
    });
}

// Initialize Select2 elements inside modals
function initModalSelect2() {
    $('.modal').on('show.bs.modal', function (event) {
        $('[data-s2="true"]').select2({
            theme: 'classic',
            tags: true,
            tokenSeparators: [','],
            width: 'resolve',
        });
    });
}

// Initialize simpleLightbox for galleries
function initGallery() {
    if ($('.gallery').length) {
        const gallery = $('.gallery a').simpleLightbox({
            maxZoom: 3,
            scrollZoomFactor: 0.025,
            disableRightClick: true,
            history: false,
        });
    }
}

// Initialize Swiffy Slider
function initSwiffySlider() {
    window.swiffyslider.init();
}

// Check if an element is in the viewport
function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

// Handle scroll event for fading in elements
function handleScroll() {
    const $fadeInElements = $('.fade-in');
    $fadeInElements.each(function() {
        if (isElementInViewport(this)) {
            $(this).addClass('is-visible');
        }
    });
}

function initFadeInElements() {
    handleScroll();
}

// Attach scroll event handler
$(document).on('scroll', handleScroll);

document.addEventListener('turbolinks:load', function() {
    var stickyElement = document.querySelector('.sticky-nav-background');

    if (stickyElement) {
        var threshold = 50; // Adjust the threshold as needed
        var initialOffset = stickyElement.getBoundingClientRect().top + window.scrollY - threshold;


        document.addEventListener('scroll', function() {
            var scroll = window.scrollY;

            // Adjust the class based on the scroll position
            if (scroll >= initialOffset) {
                stickyElement.classList.add('sticky');
            } else {
                stickyElement.classList.remove('sticky');
            }
        });
    }
});
