import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["window"]; // use a target to handle dynamic orderId
    intervalId = null;


    connect() {
        console.log("Stimulus controller connected!");

        // Code from script tag
        $('#paymentTerminalModal').on('show.bs.modal', (event) => {
            const button = $(event.relatedTarget);
            const orderId = button.data('order');
        });

        document.addEventListener('stop-polling', this.stopPolling.bind(this))

        $('#paymentTerminalModal').on('hidden.bs.modal', function (e) {
            location.reload();
        });
    }

    disconnect() {
        document.removeEventListener('stop-polling', this.stopPolling.bind(this))
    }


    pollForUpdates(orderId) {
        if (this.intervalId) { // Existing interval running, do nothing.
            return;
        }
        this.intervalId = setInterval(() => {
            fetch(`/terminal/check_for_updates?order_id=${orderId}`)
                .then(response => response.text())
                .then(data => {
                    $('#paymentTerminalWindow').html(data);
                })
                .catch(error => {
                    this.stopPolling();
                    console.log('Error:', error);
                });
        }, 5000);
    }

    stopPolling() {
        clearInterval(this.intervalId);
        this.intervalId = null;
    }

    processPayment(event) {
        console.log("TERMINAL: PROCESS PAYMENT EVENT")
        event.preventDefault();

        const form = event.currentTarget;
        const orderId = form.dataset.orderId;

        // Attach the 'ajax:success' event listener to your form
        form.addEventListener('ajax:success', (event) => {
            // 'data' is an array including three elements: [data, status, xhr]
            // 'data' will be the response body from your server
            let [data, status, xhr] = event.detail;

            // Log response
            console.log(data);

            // Check status if needed
            // if (status == "success") {}

            // Call the 'pollForUpdates' function
            this.pollForUpdates(orderId);

        }, false);

        // If you need, you can also handle 'ajax:error' for the error case
        form.addEventListener('ajax:error', function(event) {
            let [data, status, xhr] = event.detail;
            console.error('There has been a problem:', data);
        }, false);
    }
}