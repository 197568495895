document.addEventListener("turbolinks:load", function () {
    // Check if the markup_suggestion_box element exists
    if ($("#markup_suggestion_box").length > 0) {
        function updateMarkupSuggestion() {
            const unitCostInput = $("#product_unit_cost");
            const markupRate = parseFloat($("#hidden_markup_rate").val()); // Retrieve the markup rate from the hidden input
            let unitCost = parseFloat(unitCostInput.val());

            // Check if unitCost is NaN or 0
            if (isNaN(unitCost) || unitCost === 0) {
                $("#markup_suggestion_box").fadeOut(); // Hide the element
                return;
            } else {
                $("#markup_suggestion_box").fadeIn(); // Fade in the element
            }

            const suggestedMarkup = (unitCost * markupRate).toFixed(2);
            $("#markup_suggestion").text(`$${suggestedMarkup}`);
        }

        $("#product_unit_cost").on("input", updateMarkupSuggestion);
        updateMarkupSuggestion();
    }
});
