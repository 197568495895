import { Controller } from "stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
    static targets = [ "messages", "newMessage" ]

    connect() {
        this.subscription = consumer.subscriptions.create({ channel: "MessageChannel", id: this.data.get("id")}, {
            connected: this._connected.bind(this),
            disconnected: this._disconnected.bind(this),
            received: this._received.bind(this)
        })

    }

    _disconnected() {
        consumer.subscriptions.remove(this.subscription)
    }

    _connected() {
        this.scrollToBottom()
    }

    _received(data){
        if (data.message) {
            this.messagesTarget.insertAdjacentHTML('beforeend', data.message)
            this.scrollToBottom()
            $('[data-toggle="tooltip"]').tooltip()
            if (!document.hidden) {
                this.subscription.perform("touch")
            }
            if (data.sender != this.data.get("sender")) {
                this.playSound("/sounds/newmsg.mp3")
                console.log("New message from someone who isn't you!")
            }
        }

    }

    clearMessage(event) {
        this.newMessageTarget.value = ""
    }

    scrollToBottom() {
        $("#messages").scrollTop($("#messages")[0].scrollHeight)
    }

    playSound(url) {
        const audio = new Audio(url);
        audio.volume = 0.15;
        audio.play();
    }
}