// app/javascript/controllers/appointment_book_controller.js
import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    static targets = ['dateInput', 'appointmentBook']

    connect() {
        this.loadAppointmentBook()
    }

    loadAppointmentBook() {
        const date = this.dateInputTarget.value

        $.ajax({
            type: 'GET',
            url: `/appointments/day?date=${date}`,
            dataType: 'html',
            success: (data) => {
                this.appointmentBookTarget.innerHTML = data
            },
            error: (error) => {
                console.error('Error loading appointment book:', error)
                this.appointmentBookTarget.innerHTML = "<h6>Error loading appointment book: Invalid response data</h6>"
            }
        })
    }

    nextDay() {
        const nextDay = new Date(this.dateInputTarget.value)
        nextDay.setDate(nextDay.getDate() + 1)
        this.dateInputTarget.value = this.formatDate(nextDay)
        this.loadAppointmentBook()
    }

    previousDay() {
        const previousDay = new Date(this.dateInputTarget.value)
        previousDay.setDate(previousDay.getDate() - 1)
        this.dateInputTarget.value = this.formatDate(previousDay)
        this.loadAppointmentBook()
    }

    formatDate(date) {
        return date.toISOString().slice(0, 10)
    }


}
