document.addEventListener("turbolinks:load", function() {
    var visitDatePicker = document.getElementById("visitDatePicker");

    if (visitDatePicker) {
        flatpickr("#visitDatePicker", {
            minDate: "today",
            maxDate: new Date().fp_incr(365), // Today plus one year
            weekNumbers: true,
            enableTime: false,
            altInput: true,
            altFormat: "F j, Y",
            dateFormat: "Y-m-d"
        });
    }

    var eventDatePicker = document.getElementById("eventDatePicker");

    if (eventDatePicker) {
        flatpickr("#eventDatePicker", {
            minDate: "today",
            maxDate: new Date().fp_incr(1460), // Today plus one year
            weekNumbers: true,
            enableTime: false,
            altInput: true,
            altFormat: "F j, Y",
            dateFormat: "Y-m-d"
        });
    }
});

document.addEventListener("turbolinks:before-cache", function() {
    var visitDatePicker = document.getElementById("visitDatePicker");
    if (visitDatePicker && visitDatePicker._flatpickr) {
        visitDatePicker._flatpickr.destroy();
        delete visitDatePicker._flatpickr;
    }
});