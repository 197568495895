// app/javascript/packs/nested-forms/addFields.js
class addFields {
    // This executes when the function is instantiated.
    constructor() {
        this.links = document.querySelectorAll('.add_fields')
        this.iterateLinks()
    }

    iterateLinks() {
        // If there are no links on the page, stop the function from executing.
        if (this.links.length === 0) return
        // Loop over each link on the page. A page could have multiple nested forms.
        this.links.forEach(link => {
            link.addEventListener('click', e => {
                this.handleClick(link, e)
            })
        })
    }

    runSelect() {
        $('select').select2({theme: "classic", width: 'resolve' });
    }


    startLookupTool() {
        $('.product-select').on('change',(event) => {
            var currentTarget = $(event.currentTarget);
            var selectedId = $(event.currentTarget)[0].id;

            //console.log( "New Product Selected... \n Field ID: " + selectedId + "\n\n Selected: " + event.target.value );
            //console.log("Looking up pricing data...");
            $.ajax({
                type: "GET",
                url: "/products/lookup/price",
                remote: true,
                data: { product: { id: event.target.value }, requester: "bridalbit" },
                success(data) {
                    //console.log("Product Price: " + data.price);
                    var priceInput = $(event.target).closest('.nested-fields').find('.product-price');
                    var retailPriceSpan = $(event.target).closest('.nested-fields').find('.original-price');
                    var noteSpan = $(event.target).closest('.nested-fields').find('.unit-note');
                    //var currentPrice = priceInput.val();
                    //console.log("Current Field Price: " + currentPrice);
                    //console.log("Setting Field Price: " + data.price);
                    priceInput.val(data.price);
                    retailPriceSpan.html("<p>Retail Price: " + data.retail_price + " </p>");
                    if (data.note) {
                        noteSpan.html("<span class='alert-heading text-dark'><strong>⚠️ Unit Note:</strong></span>  <p>" + data.note + " </p>");
                        noteSpan.addClass("alert alert-warning").addClass("p-2")
                    } else {
                        noteSpan.html("");
                        noteSpan.removeClass("alert alert-warning").removeClass("p-2")
                    }



                    return false;
                },
                error(data) {
                    return false;
                }
            });

        });

    }

    startDiscountTool() {
        $('.discount-flat').on('change',(event) => {
            //var currentTarget = $(event.currentTarget);
            //var selectedId = $(event.currentTarget)[0].id;

            var priceInput = $(event.target).closest('.nested-fields').find('.product-price');
            var discountFlat = $(event.target).closest('.nested-fields').find('.discount-flat');
            var discountPercent = $(event.target).closest('.nested-fields').find('.discount-percent');

            console.log("Discount Flat Changed...");
            console.log("Current Price: " + priceInput.val());
            console.log("Discount Flat: " + discountFlat.val());
            console.log("Discount Percent: " + discountPercent.val());
        });

        $('.discount-percent').on('change',(event) => {
            //var currentTarget = $(event.currentTarget);
            //var selectedId = $(event.currentTarget)[0].id;

            var priceInput = $(event.target).closest('.nested-fields').find('.product-price');
            var discountFlat = $(event.target).closest('.nested-fields').find('.discount-flat');
            var discountPercent = $(event.target).closest('.nested-fields').find('.discount-percent');

            console.log("Discount Percentage Changed...");
            console.log("Current Price: " + priceInput.val());
            console.log("Discount Flat: " + discountFlat).val();
            console.log("Discount Percent: " + discountPercent.val());
        });

    }

    handleClick(link, e) {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!link || !e) return
        // Prevent the browser from following the URL.
        e.preventDefault()
        // Save a unique timestamp to ensure the key of the associated array is unique.
        let time = new Date().getTime()
        // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
        let linkId = link.dataset.id
        // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
        let regexp = linkId ? new RegExp(linkId, 'g') : null
        // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
        let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
        // Add the new markup to the form if there are fields to add.
        newFields ? link.insertAdjacentHTML('beforebegin', newFields) : null
        this.runSelect();
        this.startLookupTool();
        this.startDiscountTool();
    }
}




// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new addFields())
